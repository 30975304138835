export const useUnits = () => {
  const route = useRoute();

  const getUnitsByMeter = computed(() => {
    const meter = meterStore().getMeter(parseInt(route.params.id as string));

    if (!meter) return { id: null, symbol: 'kg', name: 'kg' };

    const area = areaStore().getArea(meter.area.id);
    const units = unitsStore().getUnits;

    if (!area || area.production_unit_id) return { id: null, symbol: 'kg', name: 'kg' };
    const meterUnit = units.find((unit) => unit.id === area.production_unit_id);

    if (!meterUnit) return { id: null, symbol: 'kg', name: 'kg' };
    return meterUnit;
  });

  const getUnitsByMeterId = (meterId: number) => {
    const meter = meterStore().getMeter(meterId);
    if (!meter) return { id: null, symbol: 'kg', name: 'kg' };
    const area = areaStore().getArea(meter.area.id);
    const units = unitsStore().getUnits;

    if (!area || area.production_unit_id) return { id: null, symbol: 'kg', name: 'kg' };
    const meterUnit = units.find((unit) => unit.id === area.production_unit_id);

    if (!meterUnit) return { id: null, symbol: 'kg', name: 'kg' };

    return meterUnit;
  };

  const getCo2byKWh = (meterId: number) => {
    const site = siteStore().getSite(globalStore().getSelectedSite[0]);
    if (typeof site !== 'number') return 0;
    if (meterStore().getMeter(meterId)?.meter_type.energy_type === 'Gas') {
      return 15;
    } else if (meterStore().getMeter(meterId)?.meter_type.energy_type === 'Electricity') {
      return 20;
    } else {
      return 0;
    }
  };

  const displayEPIByType = (options: { quantity: number; consumption: number; meterId: number; type: EPIType }) => {
    const kwhByunit = options.consumption / options.quantity;
    if (options.type === 'co2') {
      const Co2byKWh = getCo2byKWh(options.meterId);
      return `${(kwhByunit * Co2byKWh).toFixed(2)} gCO2 / ${getUnitsByMeterId(options.meterId).symbol}`;
    } else if (options.type === 'kwh') {
      return `${kwhByunit.toFixed(2)} kWh / ${getUnitsByMeterId(options.meterId).symbol}`;
    } else if (options.type === 'euro') {
      return `${kwhByunit.toFixed(2)} € / ${getUnitsByMeterId(options.meterId).symbol}`;
    }
    return '';
  };

  return {
    getUnitsByMeter,
    getUnitsByMeterId,
    displayEPIByType,
  };
};
